.home-container{
    background-image: url("https://media.istockphoto.com/id/522739960/vector/world-map-connections-on-rose-pink-background.jpg?s=612x612&w=0&k=20&c=G4U8LO7y6sigLdYKyNbieCWJRA7FELon6aRtQFPhaOY=");
    background-size: cover;
    background-position: center;
    height: 50vh;
    padding: 0px 13%;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-page-heading{
    font-family: "Roboto",sans-serif;
    font-size: 40px;
    
    
}

.home-para{
    font-family: "Roboto";
    font-size: 20px;
    align-self: flex-start;
    
}

/* .company-services{
    display: flex;
    align-items: center;
    background-color: #fff;
    width:25%;
    height: 35px;  
    padding: 15px 20px;
    border-radius: 20px; 
    
}

.select-service{
    border: none;
    outline: none;
    font-family: "Roboto",sans-serif;
    font-size: 14px;
    background-color: transparent;
    width: 100%;
    color: #8e9091;
} */

/* .company-services .box-icon{
    height: 20px;
    width:20px;
    color: #bd3371;
    margin-right: 20px;
} */

.services-container{
    display: flex;
    flex-direction: column;
    padding: 0px 13%;
}

.services-heading{
    font-family: "Roboto",sans-serif;
    font-size: 30px;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 30px;
}


.services-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0px;
    list-style-type: none;
    width: 100%;
    padding-bottom: 20px;
}

.service-item {
    font-family: "Roboto",sans-serif;
    width: 33%;
    margin-bottom: 10px;
    line-height: 26px;
    font-size: 14px;
    color: #8e9091;
    cursor: pointer;
    outline: none;
    transition: 0.2s ease-in-out;
}

.link-item{
    color: #8e9091;
}

.service-item:hover{
    color: #000;
}

.services-line{
    background-color: #bd3371;
    padding: 1px;
    width: 10%;
    margin-top: 8px;
}

.footer-section{
    background-image: url("https://i.pinimg.com/originals/83/52/fb/8352fb068b423052e7c716637b217416.jpg");
    background-size: cover;
    background-position: center;
    padding: 10px 13%;
    display: flex;
    flex-direction: column;
}

.footer-heading{
    font-family: "Roboto",sans-serif;
    font-size: 26px;
    color: #fff;
    margin-top: 40px;
}

.footer-list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0px;
    list-style-type: none;
}

.footer-item{
    display: flex;
    line-height: 26px;
}

.footer-item a{
    font-family: "Roboto",sans-serif;
    color: #bfbabc;
    font-size: 14px;
    margin-bottom: 20px;
    margin-left: 10px;
    transition: 0.2s ease-in-out;
    width: 50%;
}

.footer-item a:hover{
    color: #fff;
}

.footer-icon{
    color: #bd3371;
    height: 20px;
    width: 26px;
}

.footer-details{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-accounts{
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    padding-left: 0px;
}

.footer-social{
    background-color: #333232;
    color: #fff;
    height: 40px;
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    border-radius: 50%;
    transition: 0.2s ease-in-out;
}

.footer-social:hover{
    background-color: #bd3371;
}

.footer-rights-section{
    display: flex;
    flex-direction: column;
    background-color: #171717;
    align-items: center;
    color: #8e9091;
    font-family: "Roboto";
    font-size: 16px;
}

.footer-rights{
    margin-top: 20px;
    margin-bottom: 0px;
}

.footer-rights-link{
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.footer-rights-link span{
    color: #bd3371;
    margin-left: 5px;
    margin-right: 5px;
}

.footer-rights-link a{
    color: #fff;
}

.footer-rights-link a{
    margin-right: 5px;
}

.footer-rights-link .copy-right-icon{
    margin-right: 5px;
}

/* .whats-app-logo-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
}

.whatsapp-icon{
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000; 
    padding: 10px;
}

.whats-app-logo{
    height: 50px;
    width: 50px;
    color: #458a04;
    transition: transform 0.5s ease-in-out;
    
}

.whats-app-logo:hover{
    transform: rotate(360deg)
} */

@media screen and (max-width:765px){
    .home-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0px 30px;
    }

    .company-services{
        width: 90%;
        border-radius: 0px;
        padding: 15px 30px;
    }

    .home-page-heading{
        font-size: 26px;
        margin-bottom: 0px;
        text-align: center;
    }

    .home-para{
        font-size: 18px;
        text-align: center;
    }

    .services-container{
        padding: 10px 15px;
    }

    .service-item{
        width: 45%;
    }

    .services-heading{
        font-size: 26px;
    }

    .footer-section{
        padding: 10px 15px;
    }

    .footer-item a{
        width: 100%;
    }

    .footer-heading{
        margin-top: 10px;
    }

    .footer-details{
        display: flex;
        flex-direction: column;
    }

    .footer-rights-section{
        padding: 0px 15px;
    }

    .footer-rights{
        text-align: center;
    }

    

}
