.contact-banner-section{
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("https://media.istockphoto.com/id/467552972/photo/people-using-cellphone.jpg?s=612x612&w=0&k=20&c=9kAzhUKheekq9v9RKIkOOr_5rg0Ml2bMIYiy3EmqobQ=");
    height: 60vh;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-us-container{
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: auto;
}

.contact-heading{
    color: #fff;
    font-family: "Roboto",sans-serif;
    font-size: 35px;
}

.message-container{
    display: flex;
    flex-direction: row;
}

.message-info{
    display: flex;
    flex-direction: column;
    padding: 10px 35px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}


.message-top-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.message-subheading{
    font-family: "Roboto",sans-serif;
    font-size: 20px;
}

.message-mail-logo{
    height: 20px;
    width: 20px;
}

.message-details-info{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.input-field{
    width: 45%;
    margin-bottom: 40px;
    padding: 5px 0px;
    border: 1px solid #7a7d78;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    font-size: 14px;
}

.field{
    margin-left: auto;
}

.select-input{
    padding: 5px 0px;
    border: 1px solid #7a7d78;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    font-size: 16px;
}

.radio-group{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.radio-group label{
    align-items: center;
}

.radio-input{
    height: 15px;
    width: 15px;
}

.text-area{
    font-size: 16px;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    margin-top: 30px;
}


.submit-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 20px;
}

.submit-btn{
    background-color: #bd3371;
    color: #fff;
    font-size: 16px;
    padding: 0px 50px;
    border-radius: 60px;
    border: 1px solid #5e032c;
    cursor: pointer;
    outline: none;
    transition: 0.2s ease-in-out;
}

.submit-btn:hover{
    background-color: #5e032c;
}

.contact-info{
    display: flex;
    flex-direction: column;
    background-color: #1b1c1b;
    color: #dddedc;
    padding: 10px 10px;
    
}

.contact-subheading{
    font-size: 18px;
}

.address-container{
    display: flex;
    flex-direction: column;
}

.contact-logo{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0px;
}

.contact-logo-icon{
    margin-right: 5px;
}

.contact-text{
    font-family: "Roboto",sans-serif;
    font-size: 16px;
}

.info{
    margin-top: 0px;
    font-family: "Roboto";
}

.map-container {
    width: 60%;
    height: 450px;
    margin: auto;
    margin-top: 220px;
    margin-bottom: 20px;
}

iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.footer-section{
    background-image: url("https://i.pinimg.com/originals/83/52/fb/8352fb068b423052e7c716637b217416.jpg");
    background-size: cover;
    background-position: center;
    padding: 10px 13%;
    display: flex;
    flex-direction: column;
}

.footer-heading{
    font-family: "Roboto",sans-serif;
    font-size: 26px;
    color: #fff;
    margin-top: 40px;
}

.footer-list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0px;
    list-style-type: none;
}

.footer-item{
    display: flex;
    line-height: 26px;
}

.footer-item a{
    font-family: "Roboto",sans-serif;
    color: #bfbabc;
    font-size: 15px;
    margin-bottom: 20px;
    margin-left: 10px;
    transition: 0.2s ease-in-out;
    width: 100%;
}

.footer-item a:hover{
    color: #fff;
}

.footer-icon{
    color: #bd3371;
    height: 20px;
    width: 26px;
}

.footer-details{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-accounts{
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    padding-left: 0px;
}

.footer-social{
    background-color: #333232;
    color: #fff;
    height: 40px;
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    border-radius: 50%;
    transition: 0.2s ease-in-out;
}

.footer-social:hover{
    background-color: #bd3371;
}

.footer-rights-section{
    display: flex;
    flex-direction: column;
    background-color: #171717;
    align-items: center;
    color: #8e9091;
    font-family: "Roboto";
    font-size: 16px;
}

.footer-rights{
    margin-top: 20px;
    margin-bottom: 0px;
}

.footer-rights-link{
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.footer-rights-link span{
    color: #bd3371;
    margin-left: 5px;
    margin-right: 5px;
}

.footer-rights-link a{
    color: #fff;
}

.footer-rights-link a{
    margin-right: 5px;
}

.footer-rights-link .copy-right-icon{
    margin-right: 5px;
}

@media screen and (max-width:992px){

    .contact-heading{
        text-align: center;
        margin-top: 50px;
    }
    .contact-us-container{
        top:200px;
        bottom: 0px;
        right: 0px;
        left: 0px;
        width: 90%;
    }
    .message-container{
        display: flex;
        flex-direction: column;
        
    }

    .message-info{
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .message-subheading{
        margin-bottom: 30px;
    }

    .input-field{
        width: 100%;
    }

    .select-input{
        width: 100%;
    }

    .submit-container{
        display: flex;
        flex-direction: column;
    }

    .submit-btn{
        align-self: flex-end;
        padding: 10px 25px;
    }

    .contact-info{
        padding: 10px 35px;
    }

    .map-container{
        margin-top: 750px;
        width: 90%;
    }

    .footer-section{
        padding: 10px 15px;
    }

    .footer-item a{
        width: 100%;
    }

    .footer-heading{
        margin-top: 10px;
    }

    .footer-details{
        display: flex;
        flex-direction: column;
    }

    .footer-rights-section{
        padding: 0px 15px;
    }

    .footer-rights{
        text-align: center;
    }
}